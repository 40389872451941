import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainBlue,
  mainYellow,
  screen,
  transHover,
  dirtyWhite,
} from "../components/variables";
import { motion } from "framer-motion";
import { toSlug } from "../helpers";
import { IoArrowForwardSharp } from "react-icons/io5";
import Faq from "../components/faq";
import FeaturedProductList from "../components/featured-product-list";
import { StoreContext } from "../context";

const Wrapper = styled.div`
  background: ${mainWhite};
  padding: 34px 0 0 0;
  @media ${screen.small} {
    padding: 0;
  }

  .hero {
    overflow: hidden;

    .flex {
      display: flex;
      flex-wrap: wrap;
      @media ${screen.xsmall} {
        height: 618px;
      }

      .col {
        &--left {
          width: 100%;
          background-image: ${(props) =>
            `url(${props.background_image_large})`};
          background-repeat: no-repeat;
          background-position: 30px center;
          background-size: cover;
          padding: 0 32px;
          height: 242px;
          @media ${screen.xsmall} {
            padding: 0 0 0 48px;
            width: 55%;
            height: auto;
            background-position: center;
          }
          @media ${screen.small} {
            width: 60%;
          }
          @media ${screen.medium} {
            padding-left: 5vw;
          }
          @media ${screen.xlarge} {
            padding-left: 232px;
          }

          .title {
            color: #202121;
            max-width: 430px;
            margin: 55px 0 20px 0;
            font-size: 1.4rem;
            max-width: 235px;
            line-height: 1.33;
            @media ${screen.xsmall} {
              font-size: 2.15rem;
              max-width: 372px;
              margin: 80px 0 20px 0;
            }
            @media ${screen.small} {
              font-size: 2.5rem;
              max-width: 430px;
            }
          }

          .btn {
            background: ${mainBlue};
            border: 2px ${mainBlue} solid;
            color: ${mainWhite};
            @media ${screen.withCursor} {
              &:hover {
                background: none;
                color: ${mainBlue};
              }
            }
          }
        }

        &--right {
          width: 100%;
          @media ${screen.xsmall} {
            width: 45%;
            border-left: 14px ${mainWhite} solid;
          }
          @media ${screen.small} {
            width: 40%;
          }

          .row {
            &--top {
              background-image: ${(props) =>
                `url(${props.background_image_medium})`};
              height: 170px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              position: relative;
              @media ${screen.xsmall} {
                border-bottom: 14px ${mainWhite} solid;
                height: 65%;
              }

              .overlay {
                position: absolute;
                background: rgba(0, 0, 132, 0.5);
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: ${transHover};
                @media ${screen.withCursor} {
                  opacity: 0;
                }

                span {
                  color: ${mainWhite};
                  font-size: 1.4rem;
                  @media ${screen.xsmall} {
                    font-size: 2.15rem;
                  }
                  @media ${screen.small} {
                    font-size: 2.5rem;
                  }
                }
              }

              @media ${screen.withCursor} {
                &:hover .overlay {
                  opacity: 1;
                }
              }
            }

            &--bot {
              background-color: ${mainBlue};
              background-image: ${(props) =>
                `url(${props.background_image_small})`};
              height: 140px;
              position: relative;
              display: flex;
              @media ${screen.xsmall} {
                height: 35%;
              }

              .link {
                color: ${mainWhite};
                margin: auto;
                display: flex;
                font-size: 1.4rem;
                @media ${screen.xsmall} {
                  font-size: 1.6rem;
                }
                @media ${screen.small} {
                  font-size: 1.8rem;
                }
                @media ${screen.withCursor} {
                  &:hover {
                    color: ${mainYellow};
                  }
                }

                span {
                }

                svg {
                  margin: 0 0 0 3px;
                  font-size: 1.4rem;
                  @media ${screen.xsmall} {
                    font-size: 1.9rem;
                    margin: 0 0 0 9px;
                  }
                }
              }

              &::after {
                content: "";
                width: 80px;
                height: 80px;
                bottom: -40px;
                right: -40px;
                background: ${mainWhite};
                display: block;
                position: absolute;
                transform: rotate(45deg);
                @media ${screen.xsmall} {
                  width: 96px;
                  height: 96px;
                  bottom: -48px;
                  right: -48px;
                }
              }
            }
          }
        }
      }
    }
  }

  .featured-kind {
    padding: 45px 0 120px 0;
    @media ${screen.xsmall} {
      padding: 90px 0 120px 0;
    }

    .featured-kind-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -9px;
      justify-content: center;
      @media ${screen.xsmall} {
        margin: 0 -30px;
      }

      &__each {
        width: calc(33.33% - 18px);
        text-align: center;
        margin: 9px 9px 36px 9px;
        @media ${screen.xsmall} {
          width: calc(20% - 60px);
          margin: 30px 30px;
        }

        .name {
          color: ${mainBlue};
          font-size: 0.94rem;
          font-weight: 500;
          margin: 10px 0 0 0;
          transition: ${transHover};
          @media ${screen.xsmall} {
            font-size: 1.15rem;
            margin: 5px 0 0 0;
          }
        }

        @media ${screen.withCursor} {
          &:hover .name {
            color: ${mainYellow};
          }
        }
      }
    }

    .btn {
      border: 2px ${mainBlue} solid;
      color: ${mainBlue};
      margin: 30px auto 0 auto;
      display: block;
      text-align: center;
      @media ${screen.xsmall} {
        width: auto;
        display: table;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${mainBlue};
          color: ${mainWhite};
        }
      }
    }
  }

  .intro {
    background-image: ${(props) =>
      `url(${props.intro_background_image_mobile})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    padding: 60px 0 190px 0;
    position: relative;
    overflow: hidden;
    @media ${screen.xsmall} {
      background-image: ${(props) => `url(${props.intro_background_image})`};
      background-size: cover;
      background-position: right;
      padding: 60px 0 90px 0;
      padding: 90px 0;
      background-position: center;
    }

    .txt-wrapper {
      max-width: 600px;
      margin: 0 0 0 auto;

      .heading {
        color: ${mainBlue};
        margin: 0 0 25px 0;
        font-size: 1.4rem;
        @media ${screen.xsmall} {
          font-size: 1.95rem;
        }
        @media ${screen.small} {
          font-size: 2.15rem;
        }
      }

      .description {
        p {
          font-size: 0.95rem;
          margin: 0 0 20px 0;
          &:last-child {
            margin: 0;
          }
        }
      }

      .btn {
        border: 2px ${mainBlue} solid;
        color: ${mainBlue};
        margin: 30px 0 0 0;
        @media ${screen.withCursor} {
          &:hover {
            background: ${mainBlue};
            color: ${mainWhite};
          }
        }
      }
    }

    &::after {
      content: "";
      width: 120px;
      height: 120px;
      bottom: -60px;
      right: -60px;
      background: ${mainWhite};
      display: block;
      position: absolute;
      transform: rotate(45deg);
      @media ${screen.xsmall} {
        width: 150px;
        height: 150px;
        bottom: -75px;
        right: -75px;
      }
    }
  }

  .featured-industry {
    padding: 70px 0 60px 0;
    @media ${screen.xsmall} {
      padding: 120px 0 90px 0;
    }

    .heading {
      font-size: 0.94rem;
      font-weight: 500;
      text-align: center;
      @media ${screen.xsmall} {
        font-size: 1.15rem;
        text-align: left;
      }
    }

    .featured-industry-list {
      display: flex;
      flex-wrap: wrap;
      margin: 28px 0 0 0;
      @media ${screen.xsmall} {
        margin: 21px -7px 0 -7px;
      }

      &__each {
        width: 100%;
        margin: 0 0 12px 0;
        position: relative;
        overflow: hidden;
        @media ${screen.xsmall} {
          width: calc(25% - 14px);
          height: auto;
          margin: 7px;
        }

        .img {
          &--desktop {
            display: none;
            @media ${screen.xsmall} {
              display: block;
            }
          }

          &--mobile {
            @media ${screen.xsmall} {
              display: none;
            }
          }
        }

        .overlay {
          background: rgba(32, 33, 33, 0.5);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .btn {
            border: 2px ${mainWhite} solid;
            color: ${mainWhite};
            text-transform: uppercase;
          }

          @media ${screen.withCursor} {
            &:hover {
              background: none;
            }
          }

          @media ${screen.withCursor} {
            &:hover .btn {
              background: ${mainYellow};
              border: 2px ${mainYellow} solid;
              color: ${mainBlue};
            }
          }
        }
      }
    }
  }

  .customise {
    padding: 0 0 30px 0;
    @media ${screen.xsmall} {
      padding: 0 0 60px 0;
    }

    .flex {
      @media ${screen.xsmall} {
        display: flex;
        align-items: center;
      }

      .col {
        &--left {
          max-width: 450px;

          .heading {
            color: ${mainBlue};
            margin: 0 0 25px 0;
            font-size: 1.4rem;
            @media ${screen.xsmall} {
              font-size: 1.95rem;
            }
            @media ${screen.small} {
              font-size: 2.15rem;
            }
          }

          .description {
            p {
              font-size: 0.95rem;
              margin: 0 0 20px 0;
              &:last-child {
                margin: 0;
              }
            }
          }

          .btn {
            border: 2px ${mainBlue} solid;
            color: ${mainBlue};
            margin: 30px 0 0 0;
            display: block;
            text-align: center;
            @media ${screen.xsmall} {
              display: table;
            }
            @media ${screen.withCursor} {
              &:hover {
                background: ${mainBlue};
                color: ${mainWhite};
              }
            }
          }
        }

        &--right {
          margin: 40px 0 0 0;

          @media ${screen.xsmall} {
            width: 100%;
            padding: 0 0 0 90px;
            margin: 0;
          }
        }
      }
    }
  }

  .featured-product {
    background: ${dirtyWhite};
    padding: 90px 0 70px 0;
    @media ${screen.xsmall} {
      padding: 120px 0;
    }

    .heading {
      color: ${mainBlue};
      margin: 0 0 25px 0;
      font-size: 1.4rem;
      @media ${screen.xsmall} {
        font-size: 1.95rem;
        margin: 0 0 50px 0;
        text-align: center;
      }
      @media ${screen.small} {
        font-size: 2.15rem;
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const { setIsFormPackaging } = useContext(StoreContext);

  const {
    background_image_large,
    background_image_medium,
    background_image_small,
    banner_title,
    customise_description,
    customise_featured_image,
    customise_heading,
    featured_industry,
    featured_kind,
    featured_products,
    intro_background_image,
    intro_background_image_mobile,
    intro_description,
    intro_heading,
    primary_cta_action,
    primary_cta_label,
    secondary_cta_action,
    secondary_cta_label,
    tertiary_cta_action,
    tertiary_cta_label,
    title_tag,
    meta_description,
  } = data.content.data;

  return (
    <Layout isHomePage>
      <Seo
        title={title_tag || "Home"}
        description={meta_description}
        isHomePage
      />

      <Wrapper
        background_image_small={background_image_small.url}
        background_image_medium={background_image_medium.url}
        background_image_large={background_image_large.url}
        intro_background_image={intro_background_image.url}
        intro_background_image_mobile={intro_background_image_mobile.url}
      >
        <header className="hero">
          <div className="flex">
            <div className="col col--left">
              <h1 className="title" data-sal="slide-up" data-sal-duration="400">
                {banner_title.text}
              </h1>

              <p data-sal="fade" data-sal-duration="600" data-sal-delay="300">
                <a className="btn" href={primary_cta_action.url}>
                  {primary_cta_label}
                </a>
              </p>
            </div>
            <div className="col col--right">
              <div className="row row--top">
                <a href={secondary_cta_action.url} className="overlay">
                  <span>{secondary_cta_label}</span>
                </a>
              </div>
              <div className="row row--bot">
                <a
                  data-sal="fade"
                  data-sal-delay="500"
                  data-sal-duration="700"
                  className="link"
                  href={tertiary_cta_action.url}
                >
                  <span>{tertiary_cta_label}</span>

                  <IoArrowForwardSharp />
                </a>
              </div>
            </div>
          </div>
        </header>

        <section className="featured-kind">
          <div className="inner-wrapper">
            <ul className="featured-kind-list">
              {featured_kind.map((item, i) => (
                <li
                  key={item.kind.document.id}
                  className="featured-kind-list__each"
                  data-sal="slide-down"
                  data-sal-duration="600"
                  data-sal-delay={`${i}00`}
                >
                  <Link
                    to={`/products?f=kind&q=${toSlug(
                      item.kind.document.data.title.text
                    )}`}
                    state={{ isKind: true }}
                  >
                    <GatsbyImage
                      image={
                        item.kind.document.data.featured_image.gatsbyImageData
                      }
                      alt={
                        item.kind.document.data.featured_image.alt || "Featured"
                      }
                    />
                    <h2 className="name">
                      {item.kind.document.data.title.text}
                    </h2>
                  </Link>
                </li>
              ))}
            </ul>

            <Link className="btn" to="/products">
              VIEW PRODUCTS
            </Link>
          </div>
        </section>

        <section className="intro">
          <div className="inner-wrapper">
            <div
              className="txt-wrapper"
              data-sal="slide-left"
              data-sal-duration="700"
            >
              <h2 className="heading">{intro_heading.text}</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: intro_description.html }}
              />

              <Link className="btn" to="/about/">
                READ MORE
              </Link>
            </div>
          </div>
        </section>

        <section className="featured-industry">
          <div className="inner-wrapper">
            <h3 className="heading">SEE OUR PRODUCTS BY INDUSTRY</h3>
            <ul className="featured-industry-list">
              {featured_industry.map((item, i) => (
                <li
                  className="featured-industry-list__each"
                  key={item.industry.document.id}
                  data-sal="flip-left"
                  data-sal-duration="600"
                  data-sal-delay={`${i}00`}
                >
                  <Link
                    to={`/products?f=industry&q=${toSlug(
                      item.industry.document.data.title.text
                    )}`}
                    state={{ isIndustry: true }}
                    className="overlay"
                  >
                    <button className="btn" to="/">
                      {item.industry.document.data.title.text}
                    </button>
                  </Link>
                  {item.industry.document.data.featured_image.thumbnails.cropped
                    .gatsbyImageData && (
                    <GatsbyImage
                      className="img img--desktop"
                      image={
                        item.industry.document.data.featured_image.thumbnails
                          .cropped.gatsbyImageData
                      }
                      alt={
                        item.industry.document.data.featured_image.alt ||
                        "Featured"
                      }
                    />
                  )}

                  {item.industry.document.data.featured_image.thumbnails
                    .landscape_cropped.gatsbyImageData && (
                    <GatsbyImage
                      className="img img--mobile"
                      image={
                        item.industry.document.data.featured_image.thumbnails
                          .landscape_cropped.gatsbyImageData
                      }
                      alt={
                        item.industry.document.data.featured_image.alt ||
                        "Featured"
                      }
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section className="customise">
          <div className="inner-wrapper flex">
            <div
              className="col col--left"
              data-sal="slide-right"
              data-sal-duration="700"
            >
              <h2 className="heading">{customise_heading.text}</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: customise_description.html }}
              />
              <motion.a
                className="btn"
                href="/"
                whileTap={{ scale: 0.96 }}
                onClick={(e) => {
                  e.preventDefault();
                  setIsFormPackaging(true);
                }}
              >
                ENQUIRE
              </motion.a>
            </div>
            <div
              className="col col--right"
              data-sal="slide-up"
              data-sal-duration="700"
            >
              <GatsbyImage
                image={customise_featured_image.gatsbyImageData}
                alt={customise_featured_image.alt || "Featured"}
              />
            </div>
          </div>
        </section>

        <Faq />

        <section className="featured-product">
          <div className="inner-wrapper">
            <h3 className="heading">Featured Products</h3>
            <FeaturedProductList products={featured_products} />
          </div>
        </section>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    content: prismicHomePage {
      data {
        background_image_large {
          url
        }
        background_image_medium {
          url
        }
        background_image_small {
          url
        }
        banner_title {
          text
        }
        customise_description {
          html
        }
        customise_featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        customise_heading {
          text
        }
        featured_industry {
          industry {
            document {
              ... on PrismicIndustry {
                id
                data {
                  title {
                    text
                  }
                  featured_image {
                    alt
                    thumbnails {
                      cropped {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      landscape_cropped {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
        featured_kind {
          kind {
            document {
              ... on PrismicKind {
                id
                data {
                  title {
                    text
                  }
                  featured_image {
                    gatsbyImageData(layout: FULL_WIDTH)
                    alt
                  }
                }
              }
            }
          }
        }
        featured_products {
          product {
            document {
              ... on PrismicProduct {
                id
                data {
                  title {
                    text
                  }
                  tag
                  gallery {
                    image {
                      gatsbyImageData(layout: FULL_WIDTH)
                      alt
                    }
                  }
                  highlighted_specification_value
                }
              }
            }
          }
        }
        intro_background_image {
          url
        }
        intro_background_image_mobile {
          url
        }
        intro_description {
          html
        }
        intro_heading {
          text
        }
        meta_description
        primary_cta_action {
          url
        }
        primary_cta_label
        secondary_cta_action {
          url
        }
        secondary_cta_label
        tertiary_cta_action {
          url
        }
        tertiary_cta_label
        title_tag
      }
    }
  }
`;
